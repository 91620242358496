import React from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"

import Layout from '../components/Layout'
import Information from '../components/Information'
import SEOComponent from '../components/SEOComponent'

const StyledBlogDiv = styled.div`
  p {
    font-size: 2rem;
    line-height: 1.2em;
    text-decoration: none;
    padding-top: 1rem;
    /* No greater than 479px, no less than 280px */
    @media (max-width:479px) and (min-width:280px) {
      font-size: 1.1em;
    }​
  }

  h1 {
    font-size: 4rem;
    line-height: 1.67rem;
    padding-top: 2rem;
    /* No greater than 479px, no less than 280px */
    @media (max-width:479px) and (min-width:280px) {
      font-size: 3.1em;
    }​
  }

  h2 {
    font-size: 3.2rem;
    line-height: 1.67rem;
    padding-top: 2rem;
    padding-bottom: .67rem;
    /* No greater than 479px, no less than 280px */
    @media (max-width:479px) and (min-width:280px) {
      font-size: 2.4em;
    }​
  }

  h3 {
    font-size: 2.7rem;
    line-height: 1.67rem;
    padding-top: 2rem;
    padding-bottom: .67rem;
    /* No greater than 479px, no less than 280px */
    @media (max-width:479px) and (min-width:280px) {
      font-size: 1.67em;
    }​
  }
  padding-bottom: 1.67rem;
  max-width: 800px;
`;

export default function Blog({ data }) {
  return (
    <>
      <SEOComponent title={`Posts - Matt Oliver`}> </SEOComponent>
      <Layout>
        <Information>
          <StyledBlogDiv>
            <h2>Blog - {data.allSanityPost.totalCount} Posts</h2>

            {data.allSanityPost.edges.map(({ node }) => {
              return (
                <div>
                  <Link to={node.slug.current}><h3>{node.title}</h3></Link>
                  <h4> Posted — {new Date(Date.parse(node.publishedAt)).toLocaleDateString("en-US")} at {new Date(Date.parse(node.publishedAt)).toLocaleTimeString("en-US")}</h4>
                  <h4> by {node.author.name}</h4>
                </div>
              )
            })}

          </StyledBlogDiv>
          <hr className="nav-rule" />
        </Information>
      </Layout>
    </>
  )
}

export const query = graphql`
  query {
    allSanityPost(sort: {fields: _createdAt, order: DESC}) {
      totalCount
      edges {
        node {
          id
          title
          children {
            id
          }
          slug {
            current
          }
          author {
            name
          }
          publishedAt
        }
      }
    }
  }
`

